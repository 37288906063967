var _score = 0;
var _total = 0;

$(document).ready(function(){
    if($('.quiz-question').length > 0){
        $('.quiz-row:not(.difficulty-ultra):not(.difficulty-hardcore) .quiz-question .quiz-answer').on('click', function(event){
            event.preventDefault();
            _correct = parseInt($(this).attr('data-correct'));
            if(_correct === 1){
                _score += 1;
                $('.score .total').text(_score);
            }else {
                $(this).addClass('btn-danger').removeClass('btn-secondary');
            }
             $(this).closest('.quiz-question').find('.quiz-answer[data-correct="1"]').addClass('btn-success').removeClass('btn-secondary');
            _index = parseInt($(this).closest('.quiz-question').attr('data-question'));
            _index += 1;
            _this = this
            setTimeout(() => {
                if($('.quiz-question[data-question="'+_index+'"]').length > 0){
                    $(_this).closest('.quiz-question').addClass('d-none')
                    $('.quiz-question[data-question="'+_index+'"]').removeClass('d-none');
                    $('.amount .total').text(_index + 1);
                }else {
                    close_quiz();
                }
                
            }, 1000);
            
        })
        if(  $('.quiz-row.difficulty-ultra, .quiz-row.difficulty-hardcore').length > 0){
            $('.quiz-row.difficulty-ultra input.quiz-answer, .quiz-row.difficulty-hardcore input.quiz-answer').each(function(i, el){
                var availableTags = JSON.parse($(this).attr('data-possible'));
                el = $(el);
                $(this).autocomplete({
                    source: availableTags
                });
            })
           
            $('.quiz-row.difficulty-ultra .submit-answer, .quiz-row.difficulty-hardcore .submit-answer').on('click', function(event){
                event.preventDefault();
                var _ans = $(this).attr('data-answer');
                var _val = $(this).closest('.quiz-question').find('input.quiz-answer').val()
                var _val_el = $(this).closest('.quiz-question').find('input.quiz-answer')
                if(_val == _ans){
                    _score += 1;
                    $('.score .total').text(_score);
                    $(_val_el).addClass('border').addClass('border-success')
                }else {
                    $(_val_el).addClass('border').addClass('border-danger')
                    $(_val_el).val(_ans);
                }
                _index = parseInt($(this).closest('.quiz-question').attr('data-question'));
                _index += 1;
                _this = this;

                setTimeout(() => {
                    if($('.quiz-question[data-question="'+_index+'"]').length > 0){
                        $(_this).closest('.quiz-question').addClass('d-none')
                        $('.quiz-question[data-question="'+_index+'"]').removeClass('d-none');
                        $('.amount .total').text(_index + 1);
                    }else {
                        close_quiz();
                    }
                    
                }, 1000);
            })
         
        }
       
    }
    
})

function close_quiz() {
    $('.quiz-row').addClass('d-none');
    $('.results-row').removeClass('d-none');
}